import React from 'react'
import Ecosystem from '../components/Ecosystem'
import Layout from '../components/Layout'
import Marketing from '../components/Marketing'
import OnchainVoting from '../components/OnchainVoting'
import SmartDrop from '../components/SmartDrop'
import SolutionBanner from '../components/SolutionBanner'

const SolutionPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <SolutionBanner />
    <OnchainVoting />
    <SmartDrop />
    <Marketing />
    <Ecosystem />
  </Layout>
)

export default SolutionPage
