import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import logo1 from './SIcon3@2x.png'
import logo2 from './SIcon4@2x.png'

const Marketing = () => (
  <div className={styles.wrapper} id="marketing">
    <div className={styles.module}>
      <img src={logo1} alt="" />
      <h4>
        <FormattedMessage id="solution.smartdrop.title1" />
      </h4>
      <p>
        <FormattedMessage id="solution.smartdrop.desc1" />
      </p>
    </div>
    <div className={styles.module}>
      <img src={logo2} alt="" />
      <h4>
        <FormattedMessage id="solution.smartdrop.title2" />
      </h4>
      <p>
        <FormattedMessage id="solution.smartdrop.desc2" />
      </p>
    </div>
  </div>
)

export default Marketing
