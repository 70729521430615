import React from 'react'
import { FormattedMessage } from 'react-intl'
import image2 from './AvatarBitMart@2x.png'
import styles from './index.module.css'
import quoteLeft from './quoteLeft@2x.png'
import quoteRight from './quoteRight@2x.png'
import logo from './SGraph1@2x.png'
import titleLogo from './SIcon1@2x.png'

const OnchainVoting = () => (
  <div className={styles.wrapper} id="onchain">
    <div className={styles.top}>
      <img src={logo} alt="onChainVoting" />
      <div className={styles.topText}>
        <h3>
          <FormattedMessage id="solution.onChainVoting.title" />
        </h3>
        <p>
          <FormattedMessage id="solution.onChainVoting.slogan" />
        </p>
      </div>
    </div>
    <div className={styles.bottom}>
      <img src={titleLogo} alt="" />
      <p>
        <FormattedMessage id="solution.onChainVoting.desc" />
      </p>
      <div className={styles.comment}>
        <div className={styles.commenter}>
          <img src={image2} alt="" style={{ width: 50, margin: 0 }} />
          <span style={{ fontSize: 16, marginLeft: 20, marginRight: 20 }}>Bitmart</span>
        </div>

        <div className={styles.commentary}>
          <img src={quoteLeft} style={{ width: 13, margin: 0, marginTop: -20 }} />
          <span style={{ color: '#85559C', margin: '0 10px', lineHeight: 2 }}>
            <FormattedMessage id="solution.onChainVoting.testimonial" />
          </span>
          <img src={quoteRight} style={{ width: 13, margin: 0, marginBottom: -20 }} />
        </div>
      </div>
    </div>
  </div>
)

export default OnchainVoting
