import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './index.module.css'

const Banner = ({ intl: { locale } }) => (
  <div className={styles.banner}>
    <p className={styles.slogan}>
      <FormattedMessage id="solution.slogan" />
    </p>
    <div className={styles.action}>
      {locale === 'en' ? (
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfGD8b0hneADbfIp8OhQzO1dJaMJOe1vqMlS2NY7f9cmqGJAg/viewform?usp=sf_link"
          target="blank"
        >
          <button className={styles.more}>FREE TRIAL</button>
        </a>
      ) : (
        <a href="http://atlaspio.mikecrm.com/0uJEmbE" target="blank">
          <button className={styles.more}>申请试用</button>
        </a>
      )}
      <a href="https://ocmp.atlaspro.io" target="blank">
        <button className={styles.try}>
          <FormattedMessage id="userLogin" />
        </button>
      </a>
    </div>
  </div>
)

export default injectIntl(Banner)
