import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import bitmart from './LogoBitMart@2x.png'
import block123 from './LogoBlock123@2x.png'
import celer from './LogoCeler@2x.png'
import certik from './LogoCertik@2x.png'
import chainnews from './LogoChainNews@2x.png'
import cobo from './LogoCoboWallet@2x.png'
import coin from './LogoCoinMaster@2x.png'
import messenger from './LogoCoinMessenger@2x.png'
import contentos from './LogoContentos@2x.png'
import dappgo from './LogoDappGo@2x.png'
import review from './LogoDappReview@2x.png'
import dimension from './LogoDimension@2x.png'
import egretiar from './LogoEgretiar@2x.png'
import fbee from './LogoFbee@2x.png'
import gateio from './LogoGateio@2x.png'
import gifto from './LogoGifto@2x.png'
import hash from './LogoHashworld@2x.png'
import huobi from './LogoHuobi@2x.png'
import loopring from './LogoLoopring@2x.png'
import math from './LogoMathWallet@2x.png'
import nano from './LogoNasNano@2x.png'
import nebulas from './LogoNebulas@2x.png'
import odaily from './LogoODaily@2x.png'
import rating from './LogoRatingToken@2x.png'
import safe from './LogoSafeWallet@2x.png'

const Ecosystem = () => (
  <div className={styles.media}>
    <h1 className={styles.title}>
      <FormattedMessage id="ecosystem" />
    </h1>
    <div className={styles.logos}>
      <div className={styles.logo}>
        <img src={huobi} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={rating} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={contentos} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={bitmart} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={gateio} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={hash} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={fbee} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={egretiar} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={gifto} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={loopring} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={safe} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={cobo} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={math} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={certik} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={nano} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={odaily} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={chainnews} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={review} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={dappgo} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={block123} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={coin} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={messenger} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={celer} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={dimension} alt="" />
      </div>
      <div className={styles.logo}>
        <img src={nebulas} alt="" />
      </div>
    </div>
  </div>
)

export default Ecosystem
