import React from 'react'
import { FormattedMessage } from 'react-intl'
import image2 from './AvatarNASNano@2x.png'
import styles from './index.module.css'
import quoteLeft from './quoteLeft@2x.png'
import quoteRight from './quoteRight@2x.png'
import logo from './SGraph2@2x.png'
import titleLogo from './SIcon2@2x.png'

const SmartDrop = () => (
  <div className={styles.wrapper} id="smartdrop">
    <div className={styles.top}>
      <div className={styles.topText}>
        <h3>
          <FormattedMessage id="solution.smartdrop.title" />
        </h3>
        <p>
          <FormattedMessage id="solution.smartdrop.slogan" />
        </p>
      </div>
      <img src={logo} alt="onChainVoting" />
    </div>
    <div className={styles.bottom}>
      <img src={titleLogo} alt="" />
      <p>
        <FormattedMessage id="solution.smartdrop.desc" />
      </p>
      <div className={styles.comment}>
        <div className={styles.commenter}>
          <img src={image2} alt="" style={{ width: 50, margin: 0 }} />
          <span style={{ fontSize: 16, marginLeft: 20, marginRight: 20 }}>Nas Nano</span>
        </div>

        <div className={styles.commentary}>
          <img src={quoteLeft} style={{ width: 13, margin: 0, marginTop: -20 }} />
          <span style={{ color: '#85559C', margin: '0 10px', width: 500, lineHeight: 2 }}>
            <FormattedMessage id="solution.smartdrop.testimonial" />
          </span>
          <img src={quoteRight} style={{ width: 13, margin: 0, marginBottom: -20 }} />
        </div>
      </div>
    </div>
  </div>
)

export default SmartDrop
